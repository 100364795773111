import React, { useState } from 'react';
import { Alert, Button, MenuItem, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { trackClickButtonEvent } from '../../../utils/functions/trackEvent';

const PURPOSES: string[] = [
  'Clases todas las semanas porque me cuesta la materia',
  'Aprender uno o más temas',
  'Practicar ejercicios',
  'Entender ejercicios',
  'Preparar un examen',
  'Preparar una materia previa',
  'Otro'
];

const ContactForm = () => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [institution, setInstitution] = useState<string | undefined>(undefined);
  const [course, setCourse] = useState<string | undefined>(undefined);
  const [purpose, setPurpose] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<string | undefined>(undefined);
  const [expiration, setExpiration] = useState<Moment | undefined>(undefined);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    trackClickButtonEvent('send_school_teacher_request');
    const message = `Hola!, Soy *${name}* me contacto para *Estudi Colegios*\nColegio: *${institution}*\nMateria: *${course}*\nNecesidad: *${purpose}*\nContexto:\n\n${context}${
      expiration ? `\n\nNecesito las clases antes de: *${expiration.format('DD/MM/YYYY')}*` : ''
    }`;
    const externalUrl = `https://api.whatsapp.com/send?phone=5491161297377&text=${encodeURIComponent(
      message
    )}`;

    window.location.href = externalUrl;
  };

  return (
    <div className="teacherRequest">
      <div className="teacherRequestTitle">Encontremos tu profe ideal</div>
      <p style={{ marginTop: '4%', marginLeft: '5%', marginRight: '5%', textAlign: 'justify' }}>
        En <b>estudi</b> sabemos que cada persona es única y nos apasiona encontrarles a los mejores
        profesores particulares. Con más de 6 años ayudando a estudiantes universitarios, ahora
        también acompañamos a estudiantes de colegios secundarios!
      </p>
      <p style={{ marginLeft: '5%', marginRight: '5%', textAlign: 'justify' }}>
        Con los profesores particulares de <b>estudi</b>, podes llevar las materias al día, preparar
        exámenes, aprender un tema que no entiendas y mucho más, ¡todo con un profesor a tu medida!
        Si estás buscando apoyo en las materias o tenes hijos en etapa de colegio secundario, no
        dudes en contactarnos! Rellená esta información y en menos de 48hrs te conectaremos con el
        profe ideal para vos
      </p>
      <div className="teacherRequestContainer">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <form
            className="teacherRequestForm"
            id="teacherRequestForm"
            name="teacher-request-form"
            acceptCharset="utf-8"
            onSubmit={handleSubmit}>
            <TextField
              id="requester_name"
              key="requester_name"
              label="Nombre y apellido del alumno/a"
              placeholder="Nombre y apellido del alumno/a"
              variant="outlined"
              margin="dense"
              type="text"
              name="requester_name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              required
            />
            <TextField
              id="institution"
              key="institution"
              label="Colegio"
              placeholder="Institución / colegio"
              variant="outlined"
              margin="dense"
              type="text"
              name="institution"
              value={institution}
              onChange={(e: any) => setInstitution(e.target.value)}
              required
            />
            <TextField
              id="course"
              key="course"
              label="Materia"
              placeholder="Curso / materia"
              variant="outlined"
              margin="dense"
              type="text"
              name="course"
              value={course}
              onChange={(e: any) => setCourse(e.target.value)}
              required
            />
            <TextField
              id="purpose"
              key="purpose"
              label="¿Qué estas necesitando?"
              placeholder="¿Qué estas necesitando?"
              variant="outlined"
              margin="dense"
              name="purpose"
              value={purpose}
              select
              onChange={(e: any) => setPurpose(e.target.value)}
              required>
              {PURPOSES.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </TextField>
            <TextField
              id="context"
              key="context"
              multiline
              rows={6}
              label="Contanos más"
              placeholder="¿Cómo describirías la situación con la materia? ¿Qué crees que se necesita?, ¿Cuántas clases crees que necesitas o con qué frecuencia? Contanos lo que creas que necesitamos saber para ayudarte mejor"
              variant="outlined"
              margin="dense"
              type="text"
              name="context"
              value={context}
              onChange={(e: any) => setContext(e.target.value)}
              required
            />
            <DatePicker
              label="¿Necesitas las clases antes de alguna fecha?"
              slotProps={{
                textField: {
                  helperText: '(Opcional) Ej: Fecha de un examen'
                }
              }}
              sx={{ marginTop: '1%', marginBottom: '1%' }}
              value={expiration}
              format="DD.MM.YYYY"
              disablePast
              onChange={setExpiration as any}
            />
            <Alert severity="info">
              Este formulario se envia por WhatsApp, si tenes más consultas en específico vas a
              poder hacerlas a través de ese medio.
            </Alert>
            <div className="teacherRequestFormButton">
              <Button
                type="submit"
                variant="contained"
                disabled={!name || !institution || !course || !purpose || !context}>
                Enviar
              </Button>
            </div>
          </form>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useEffect, useState } from 'react';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import classNames from 'classnames';
import './Calendar.css';
import { useAsyncValue } from 'react-router-dom';
import { CalendarInfo } from '../Results/Results.types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import { trackClickEvent } from '../../utils/functions/trackEvent';

export interface SelectedSlot {
  start: Date;
  startStr: String;
  end: Date;
  endStr: String;
}
const TeachersCalendar = ({
  businessHours,
  handleConfirmation,
  teacherExternalId,
  reservationType
}: {
  businessHours: any;
  handleConfirmation: (selectedSlot: any) => void;
  teacherExternalId: string;
  reservationType: string;
}) => {
  const [userReachCalendar, setUserReachCalendar] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot>();
  const calendarData = useAsyncValue();

  const { events } = calendarData as CalendarInfo;

  const handleSelected = (info: any) => {
    setSelectedSlot(info);
    if (!userReachCalendar) {
      setUserReachCalendar(true);
      trackClickEvent('calendar_slot', teacherExternalId);
    }
  };

  const handleSlotConfirmation = () => {
    handleConfirmation(selectedSlot);
  };

  const smallDevice = useMediaQuery('(max-width:768px)');

  const handleContentLoaded = () => {
    let calendarEl: HTMLElement = document.getElementById('calendar')!;

    let calendar = new Calendar(calendarEl, {
      plugins: [interactionPlugin, timeGridPlugin],
      initialView: 'customView',
      views: {
        customView: {
          type: 'timeGrid',
          duration: { days: smallDevice ? 2 : 7 }
        }
      },
      headerToolbar: {
        start: 'title',
        center: '',
        end: 'prev,next'
      },
      contentHeight: smallDevice ? 600 : 'auto',
      longPressDelay: 1,
      navLinks: false,
      editable: true,
      locale: esLocale,
      eventStartEditable: false,
      selectable: true,
      selectConstraint: 'businessHours',
      selectOverlap: false,
      eventOverlap: false,
      allDaySlot: false,
      snapDuration: '00:30:00',
      expandRows: true,
      unselectAuto: true,
      nowIndicator: false,
      slotDuration: '01:00:00',
      slotMinTime: '08:00:00',
      slotMaxTime: '22:00:00',
      defaultTimedEventDuration: '01:00:00',
      forceEventDuration: true,
      validRange: function (nowDate) {
        let startDate = new Date(nowDate.getTime() + 3 * 36e5);

        const dayOffset = startDate.getHours() >= 21 ? 2 : 1;

        startDate.setDate(nowDate.getDate() + dayOffset);

        let offsetDate = new Date(nowDate);

        offsetDate.setMonth(nowDate.getMonth() + 1);
        offsetDate.setDate(offsetDate.getDate() - 1);

        return {
          start: startDate,
          end: offsetDate
        };
      },
      businessHours: businessHours,
      select: (info: any) => {
        if (Math.abs(info.start - info.end) / 36e5 < 1) {
          let minEndDate: Date = new Date(info.start);

          minEndDate.setHours(minEndDate.getHours() + 1);
          info.end = minEndDate;
          handleSelected(info);
          calendar.select(info);
        } else if (Math.abs(info.start - info.end) / 36e5 > 4) {
          calendar.unselect();
        } else {
          handleSelected(info);
        }
      },
      selectAllow: function (select) {
        const today = new Date();

        return select.start >= new Date(today.getTime() + 24 * 36e5);
      },
      dayMaxEvents: true, // allow "more" link when too many events
      events: events
        .map((e) => ({
          start: e.start,
          end: e.end,
          editable: false,
          title: e.type === 'reservation_in_progress' ? 'Pendiente' : 'Ocupado',
          backgroundColor: e.type === 'reservation_in_progress' ? '#F9BF6D' : '#78818C',
          borderColor: e.type === 'reservation_in_progress' ? '#F9BF6D' : '#78818C'
        }))
        .concat({
          start: new Date().toISOString(),
          end: new Date(new Date().getTime() + 24 * 36e5).toISOString(),
          editable: false,
          title: 'No disponible',
          backgroundColor: '#78818C',
          borderColor: '#78818C'
        })
    });

    calendar.render();
  };

  useEffect(() => {
    handleContentLoaded();
  }, [calendarData]);

  return (
    <div className="calendarContainer">
      <h4 className="calendarTitle">Elegí horario para tu clase</h4>
      <div className="calendar" id="calendar" />
      <Tooltip
        title={`Selecciona un bloque blanco (1h) o arrastra para múltiples bloques y ${reservationType.toLowerCase()} tu clase`}>
        <div>
          {userReachCalendar && (
            <button
              className={classNames('calendarButton', {
                ['calendarButtonDisabled']: !selectedSlot?.startStr
              })}
              onClick={handleSlotConfirmation}
              disabled={!selectedSlot?.startStr}>
              {`${reservationType} tu clase`}
            </button>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default TeachersCalendar;
